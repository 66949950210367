<template>
  <k-layout class="tw-bg-white">
    <template #default>
      <k-container class="tw-py-16">
        <template #default>
          <div class="tw-w-full tw-mx-auto tw-max-w-3xl">
            <h1 class="tw-heading-1">{{ $t('newsfeed.title') }}</h1>

            <div class="tw-divide-y tw-divide-gray-200 tw-border-t tw-mt-12 tw-border-gray-200 tw-pt-6">
              <article v-for="item in newsfeed" :key="item.id" class="tw-relative">
                <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-py-6">
                  <div class="tw-flex-shrink-0 tw-aspect-ratio-16:9 tw-w-full md:tw-max-w-xs">
                    <img :src="item.images[0]" alt="" class="tw-w-full tw-h-full tw-object-cover" />
                  </div>

                  <div class="tw-h-full tw-mt-4 md:tw-mt-0 md:tw-ml-10">
                    <p class="tw-text-gray-400 tw-text-sm">
                      {{ item.timestamp | formatDateRelativeSimple }}
                    </p>

                    <p>
                      <a
                        :href="item.link"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="tw-heading-4 tw-inline-block tw-font-normal tw-mt-2"
                      >
                        <span aria-hidden="true" class="tw-absolute tw-inset-0"><!-- Expand Tap Target --></span>
                        {{ item.title }}
                      </a>
                    </p>

                    <div class="tw-flex tw-items-center tw-mt-4">
                      <img
                        :src="item.icon"
                        @error="$event.target.src = '/web/home/default_favicon.png'"
                        class="tw-rounded-full tw-w-5 tw-h-5 tw-mr-2 tw-flex-shrink-0"
                        alt=""
                      />
                      <p class="tw-text-gray-500 tw-text-xs">{{ item.siteName }}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <k-button type="button" @click="fetchNext" class="tw-w-full tw-mt-4">
              {{ $t('newsfeed.action') }}
            </k-button>
          </div>
        </template>
      </k-container>
    </template>
  </k-layout>
</template>

<script>
export default {
  computed: {
    newsfeed() {
      return this.$store.getters['NEWSFEED/newsfeed']
    }
  },

  methods: {
    fetchNext() {
      this.$store.dispatch('NEWSFEED/fetchNext')
    }
  }
}
</script>
